<template>
    <div class="dashboard-wrapper">
        <clubsidebar></clubsidebar>
        <clubnavigation></clubnavigation>
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mt-3">
                <div class="card-header bg-white">
                    <h3 class="card-title font-weight-bolder">Club Registrations List</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                    <table id="example1" class="table table-bordered table-striped">
                    <thead>
                    <tr>                
                        <th>Person ID</th>
                        <th>Club Name</th>
                        <th>Primary Contact</th>
                        <th>Club Location</th>
                        <th>Club Officer</th>
                        <th>Registration Amount</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1234</td>
                            <td>Eagles</td>                
                            <td>Jim Philips</td>
                            <td>Tampa, FL</td>
                            <td>Hermann Hesse</td>
                            <td>$100</td>
                            <td class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                <a href="#" class="btn btn-info"><i class="fas fa-eye"></i></a>
                                <a href="#" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>1224</td>
                            <td>Ghostbusters</td>                
                            <td>John Akeman</td>
                            <td>New York, NY</td>
                            <td>Bill Jones</td>
                            <td>$200</td>
                            <td class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                <a href="#" class="btn btn-info"><i class="fas fa-eye"></i></a>
                                <a href="#" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>1254</td>
                            <td>Avengers</td>                
                            <td>Alice Burton</td>
                            <td>Los Angeles, CA</td>
                            <td>Bill Jones</td>
                            <td>$300</td>
                            <td class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                <a href="#" class="btn btn-info"><i class="fas fa-eye"></i></a>
                                <a href="#" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>3241</td>
                            <td>Sultans</td>                
                            <td>Andreas Willis</td>
                            <td>Houston, TX</td>
                            <td>Bill Jones</td>
                            <td>$200</td>
                            <td class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                <a href="#" class="btn btn-info"><i class="fas fa-eye"></i></a>
                                <a href="#" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                </div>
                            </td>
                        </tr>              
                    </tbody>
                    <!-- <tfoot>
                    <tr>
                        <th>Rendering engine</th>
                        <th>Browser</th>
                        <th>Platform(s)</th>
                        <th>Engine version</th>
                        <th>CSS grade</th>
                    </tr>
                    </tfoot> -->
                    </table>
                </div>
                <!-- /.card-body -->
                </div>
                <!-- /.card -->
                </div>
            </div>
        </div>
        <clubdboardfooter></clubdboardfooter>
    </div>
</template>

<script>
import clubnavigation from "./components/Navigation";
import clubsidebar from "./components/Sidebar";
import clubdboardfooter from "./components/Footer";
export default {
    name: "registrations",
    components: {clubnavigation, clubsidebar,clubdboardfooter},
}
</script>